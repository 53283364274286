import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import {
  deleteMethod,
  getMethod,
  postMethodFormData,
} from "../api";
import Breadcrumbs from "../layout/breadcrumb";
import Layout from "./Layout";
import SweetAlert from "sweetalert2";
import Loader from "../layout/loader/Loader";
import TableScroll from "../utils/TableScroll";
import { BANNER_API, BANNER_CREATE_API, BANNER_DELETE_API, BANNER_UPDATE_API } from "../api/routes";
import { currentDate, decodeData } from "../utils/Helper";
import ModalImage from "react-modal-image";

const Banner = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [refr, setRefr] = useState(true);
  const [editBanner, setEditBanner] = useState(false);
  const [createBanner, setCreateBanner] = useState(false);
  const [bannerImage, setBannerImage] = useState("");
  const [editBannerImage, setEditBannerImage] = useState("");
  const [bannerImageid, setBannerImageid] = useState("");
  const [bannerImageValidate, setBannerImageValidate] = useState("");
  const [editBannerImageValidate, setEditBannerImageValidate] = useState("");

  useEffect(() => {
    (async () => {
      const res = await getMethod(
        `${BANNER_API}?app_name_id=${process.env.REACT_APP_NAME_ID}`
      );
      res?.status === "success" && setData(res?.data);
    })();
  }, [refr]);

  const editOpenModal = () => {
    setEditBanner(!editBanner);
  };

  const createOpenModal = () => {
    setCreateBanner(!createBanner);
  };

  const createSubmitHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("photo", bannerImage);
    formData.append("app_name_id", process.env.REACT_APP_NAME_ID);
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    if (!token) return;
    setLoading(true);
    const res = await postMethodFormData(
      BANNER_CREATE_API,
      formData,
      token.token
    );
    res && setLoading(false);
    if (res?.status === "success") {
      setCreateBanner(false);
      setRefr(!refr);
      setBannerImage("");
      return;
    } else {
      SweetAlert.fire({
        icon: "error",
        width: 300,
        title: 'Sorry!',
        text: (res?.field ?? '') + ' ' + (res?.message ?? "Something wrong, Try again!"),
      });
      return;
    }
  };

  const editSubmitHandler = async () => {
    const formData = new FormData();
    formData.append("photo", editBannerImage);
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    if (!token) return;
    const res = await postMethodFormData(
      `${BANNER_UPDATE_API(bannerImageid)}?updated_at=${currentDate()}`,
      formData,
      token.token,
      "PUT"
    );
    res && setLoading(false);
    if (res?.status === "success") {
      setEditBanner(false);
      setRefr(!refr);
      setBannerImage("");
      return;
    } else {
      SweetAlert.fire({
        icon: "error",
        width: 300,
        title: 'Sorry!',
        text: (res?.field ?? '') + ' ' + (res?.message ?? "Something wrong, Try again!"),
      });
      return;
    }
  };

  const deleteHandler = async (id) => {
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    if (!token) return;
    SweetAlert.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      width: 300,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        (async () => {
          const res = await deleteMethod(
            BANNER_DELETE_API(id),
            token.token
          );
          res && setLoading(false);
          if (res?.status === "success") {
            SweetAlert.fire({
              title: "Success!",
              text: "You has been deleted.",
              icon: "success",
              width: 300,
            });
            setRefr(!refr);
            return;
          } else {
            SweetAlert.fire({
              title: "Sorry!",
              text: res?.message ?? "Something wrong, Try again!",
              icon: "error",
              width: 300,
            });
            return;
          }
        })();
      }
    });
  };
  
  return (
    <>
      <Layout>
        <Breadcrumbs title="Banner" />
        <Container fluid={true}>
        {loading && <Loader />}
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader className="py-4 d-flex justify-content-end">
                  <Button
                    color=""
                    className="text-white"
                    style={{ backgroundColor: "#001753" }}
                    onClick={createOpenModal}
                  >
                    Create
                  </Button>
                </CardHeader>
                <CardBody className="position-relative">
                  <TableScroll>
                    <Table className="table-border-vertical">
                      <thead>
                        <tr className="bg-dark">
                          <th className="text-center text-white" scope="col">
                            <b>No.</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Photo</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Action</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.length > 0 &&
                          data?.map((el, i) => (
                            <tr key={i} className="align-middle">
                              <th className="text-center" scope="row">{`${
                                i + 1
                              }.`}</th>
                              <td className="text-center">
                                 <div
                                  style={{
                                    width: "200px",
                                    borderRadius: "5px",
                                    overflow: "hidden",
                                    margin: "auto",
                                  }}
                                >
                                  <ModalImage
                                    alt={`Banner Photo - ${i+1}`}
                                    small={el.photo}
                                    large={el.photo}
                                  />
                                </div>
                              </td>
                              <td className="text-center">
                                <div>
                                  <span
                                    onClick={() => {
                                      editOpenModal();
                                      setBannerImageid(el.id);
                                    }}
                                  >
                                    <i
                                      className="fa fa-pencil"
                                      style={{
                                        width: 35,
                                        fontSize: 18,
                                        padding: 11,
                                        color: "rgb(40, 167, 69)",
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  </span>
                                  <span
                                    onClick={() => {
                                      deleteHandler(el.id);
                                    }}
                                  >
                                    <i
                                      className="fa fa-trash"
                                      style={{
                                        width: 35,
                                        fontSize: 18,
                                        padding: 11,
                                        color: "#dc3545",
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  </span>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </TableScroll>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
      {
        <Modal isOpen={createBanner} toggle={createOpenModal} centered>
          <Form onSubmit={createSubmitHandler}>
            <ModalHeader toggle={createOpenModal}>Create Banner</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label htmlFor="">Photo</Label>
                <Input
                  className="form-control"
                  type="file"
                  placeholder=""
                  onChange={(e) => {
                    setBannerImage(e.target.files[0]);
                    setBannerImageValidate("");
                  }}
                />
                {bannerImageValidate && (
                  <div className="invalid-feedback d-block">
                    {bannerImageValidate}
                  </div>
                )}
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={createOpenModal}>
                Close
              </Button>
              <Button color="primary" type="submit">
                Save
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      }
      {
        <Modal isOpen={editBanner} toggle={editOpenModal} centered>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              editSubmitHandler();
            }}
          >
            <ModalHeader toggle={editOpenModal}>Edit Banner</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label htmlFor="">Photo</Label>
                <Input
                  className="form-control"
                  type="file"
                  placeholder=""
                  onChange={(e) => {
                    setEditBannerImage(e.target.files[0]);
                    setEditBannerImageValidate("");
                  }}
                />
                {editBannerImageValidate && (
                  <div className="invalid-feedback d-block">
                    {editBannerImageValidate}
                  </div>
                )}
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={editOpenModal}>
                Close
              </Button>
              <Button color="primary" type="submit">
                Save
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      }
    </>
  );
};

export default Banner;
