const domain = process.env.REACT_APP_DOMAIN;

export const APP_NAME_API = `${domain}api/app-name`
export const APP_NAME_UPDATE_API = id => `${domain}api/app-name/${id}/update`
export const LOGIN_API = `${domain}api/admin-login`
export const LOGOUT_API = `${domain}api/logout`
export const USER_API  = `${domain}api/users`
export const USER_INFO_API  = `${domain}api/user-info`
export const STREAMER_INFO_API  = `${domain}api/streamer-info`
export const LOGIN_IP_INFO_API  = `${domain}api/login-ip-info`
export const CHANGE_SELF_PASSWORD_API  = `${domain}api/change-self-password`
export const USER_UPDATE_API  = user_id => `${domain}api/user/${user_id}/update`
export const USER_UPPER_INFO_API  = `${domain}api/upper-info`
export const PAYMENT_PROVIDER_API  = `${domain}api/payment-providers`
export const PAYMENT_PROVIDER_UPDATE_API  = id => `${domain}api/payment-providers/${id}/update`
export const PAYMENT_PROVIDER_DELETE_API  = id => `${domain}api/payment-providers/${id}/destroy`
export const REGISTER_API  = `${domain}api/register`
export const GET_OTP_API  = `${domain}api/getOTP`
export const DEPOSIT_API  = `${domain}api/deposits`
export const DEPOSIT_APPROVE_API  = user_id => `${domain}api/deposit-confirm/${user_id}/action`
export const DEPOSIT_REJECT_API  = user_id => `${domain}api/deposit-reject/${user_id}/action`
export const WITHDRAW_API  = `${domain}api/withdraws`
export const WITHDRAW_APPROVE_API  = user_id => `${domain}api/withdraw-confirm/${user_id}/action`
export const WITHDRAW_REJECT_API  = user_id => `${domain}api/withdraw-reject/${user_id}/action`
export const MANUAL_WALLET_TRANSFER_API  = `${domain}api/wallet-transfer`
export const STREAMER_MANUAL_WALLET_TRANSFER_API  = `${domain}api/streamer-wallet-transfer`
export const BANNER_API  = `${domain}api/banners`
export const BANNER_CREATE_API  = `${domain}api/create-banners`
export const BANNER_UPDATE_API  = id => `${domain}api/update-banners/${id}`
export const BANNER_DELETE_API  = id => `${domain}api/delete-banners/${id}`
export const CAPTION_API  = `${domain}api/caption`
export const CAPTION_UPDATE_API  = id => `${domain}api/update-caption/${id}`
export const POPUP_API  =`${domain}api/popup`
export const POPUP_UPDATE_API  = id => `${domain}api/update-popup/${id}`
export const PROMOTION_API  = `${domain}api/promotions`
export const PROMOTION_CREATE_API  = `${domain}api/create-promotions`
export const PROMOTION_UPDATE_API  = id => `${domain}api/update-promotions/${id}`
export const PROMOTION_DELETE_API  = id => `${domain}api/delete-promotions/${id}`
export const STREAMER_API  = `${domain}api/streamers`
export const STREAMER_UPDATE_API  = id => `${domain}api/streamers/${id}/update`
export const STREAMER_DELETE_API  = id => `${domain}api/streamers/${id}/destroy`
// report 
export const DASHBOARD_API  = `${domain}api/dashboard-report`
export const DASHBOARD_USER_GROWTH_API  = `${domain}api/each-month-user-count`
export const FINANCE_REPORT  = `${domain}api/finance-report`
export const PROVIDER_REPORT_API  = `${domain}api/provider-report`
export const USER_REPORT_API  = `${domain}api/user-report`
export const DEPOSIT_WITHDRAW_USER_REPORT_API  = `${domain}api/deposit-withdraw-user-report`
export const BALANCE_TRANSFER_REPORT_API  = `${domain}api/balance-transfer-history`
export const PROMOTION_REPORT_API  = `${domain}api/promotion-report`
export const STREAMER_REPORT_API  = `${domain}api/streamer-report`
// game
export const PROVIDER_API  = `${domain}api/providers`
export const PROVIDER_UPDATE_API  = id => `${domain}api/providers/${id}/update`
export const PROVIDER_DELETE_API  = id => `${domain}api/providers/${id}/destroy`
export const PROVIDER_TYPE_API  = `${domain}api/provider-types`
export const PROVIDER_TYPE_UPDATE_API  = id => `${domain}api/provider-types/${id}/update`
export const PROVIDER_TYPE_DELETE_API  = id => `${domain}api/provider-types/${id}/destroy`
export const PROVIDER_TYPE_CODE_API  = `${domain}api/provider-type-code`
export const PROVIDER_CONNECT_LIST_API  = `${domain}api/provider-connect-lists`
export const PROVIDER_CONNECT_LIST_UPDATE_API  = id => `${domain}api/provider-connect-lists/${id}/update`
export const PROVIDER_CONNECT_LIST_DELETE_API  = id => `${domain}api/provider-connect-lists/${id}/destroy`
export const GAME_API = `${domain}api/games`
export const GAME_UPDATE_API = id => `${domain}api/games/${id}/update`
export const GAME_LIST_API = `${domain}api/game-lists`
export const GAME_LIST_DETAIL_API = id =>  `${domain}api/game-lists/${id}`
export const GAME_LIST_UPDATE_API = id =>  `${domain}api/game-lists/${id}/update`
export const GAME_LIST_COUNT_API = `${domain}api/website-show-game-list-count`
// betting
export const BETTING_HISTORY_API = `${domain}api/betting-history`
export const TRANSACTION_HISTORY_API = `${domain}api/transaction-history`
export const SLOT_BETTING_HISTORY_API = `${domain}api/sport-book/slot/betting-history`


