import React, {Suspense, useEffect, useState} from 'react';
import {BrowserRouter as Router,Route, Routes} from 'react-router-dom';
import Signin from '../auth/Signin';
import Deposit from '../components/Deposit';
import User from '../components/User';
import Withdraw from '../components/Withdraw';
import Loader from '../layout/loader/Loader';
import ValidateToken from '../auth/ValidateToken';
import PaymentProvider from '../components/PaymentProvider';
import TransactionHistory from '../components/TransactionHistory';
import ReportProvider from '../components/ReportProvider';
import ReportUsers from '../components/ReportUsers';
import BettingBody from '../components/BettingBody';
import GameBetting from '../components/GameBetting';
import Banner from '../components/Banner';
import Caption from '../components/Caption';
// import Smsphone from '../components/Smsphone';
import BettingParlay from '../components/BettingParlay';
import UserCreate from '../components/UserCreate';
import ManualDeposit from '../components/ManualDeposit';
import ManualWithdraw from '../components/ManualWithdraw';
import BalanceTrans from '../components/BalanceTrans';
import Dashboard from '../components/Dashboard';
import Providers from '../components/Providers';
import GameTypes from '../components/GameTypes';
import GameConnect from '../components/GameConnect';
import Games from '../components/Games';
import GamesList from '../components/GamesList';
import { useSelector } from 'react-redux';
import PopupBox from '../components/PopupBox';
import Promotions from '../components/Promotions';
import PromotionUsers from '../components/PromotionUsers';
import PromotionUsersDetail from '../components/PromotionUsersDetail';
import ChangeSelfPassword from '../components/ChangeSelfPassword';
import ReportDepositWithdraw from '../components/ReportDepositWithdraw';
import { decodeData } from '../utils/Helper';
import CreateStreamer from '../components/CreateStreamer';
import ReportStreamer from '../components/ReportStreamer';
import ReportStreamerDetail from '../components/ReportStreamerDetail';
// import ContactUs from '../components/ContactUs';
// import AppName from '../components/AppName';

const Routers = () => {
  const [role,setRole] = useState("");
  const {value} = useSelector(state => state.auth);

  useEffect(()=>{
      const getAuth = localStorage.getItem('y_g_n_d_a');
      if(getAuth){
        const token = decodeData(getAuth);
        setRole(token?.user_role);
      };
  },[value]);

  return (
    <Router basename={'/'}>
        <Suspense fallback={<Loader />}>
            <Routes>
              <Route path="/" element={<ValidateToken><Dashboard/></ValidateToken>}></Route>
              <Route path={`/admin/login`} element={<Signin/>}></Route>
              <Route path={`/admin/users`} element={<ValidateToken><User/></ValidateToken>}></Route>
              {
                role === "admin" &&
                <>
                  <Route path="/admin/dashboard" element={<ValidateToken><Dashboard/></ValidateToken>}></Route>
                  {/* <Route path="/admin/app-name" element={<ValidateToken><AppName/></ValidateToken>}></Route> */}
                  <Route path={`/admin/create`} element={<ValidateToken><UserCreate/></ValidateToken>}></Route>
                  <Route path={`/admin/create-streamer`} element={<ValidateToken><CreateStreamer/></ValidateToken>}></Route>
                  <Route path={`/admin/deposit`} element={<ValidateToken><Deposit/></ValidateToken>}></Route>
                  <Route path={`/admin/withdraw`} element={<ValidateToken><Withdraw/></ValidateToken>}></Route>
                  <Route path={`/admin/manual-deposit`} element={<ValidateToken><ManualDeposit/></ValidateToken>}></Route>
                  <Route path={`/admin/manual-withdraw`} element={<ValidateToken><ManualWithdraw/></ValidateToken>}></Route>
                  <Route path={`/admin/change-self-password`} element={<ValidateToken><ChangeSelfPassword/></ValidateToken>}></Route>
                  <Route path={`/admin/games-betting`} element={<ValidateToken><GameBetting/></ValidateToken>}></Route>
                  <Route path={`/admin/transaction-history`} element={<ValidateToken><TransactionHistory/></ValidateToken>}></Route>
                  <Route path={`/admin/balance-transfer`} element={<ValidateToken><BalanceTrans/></ValidateToken>}></Route>
                  <Route path={`/admin/reports/provider`} element={<ValidateToken><ReportProvider/></ValidateToken>}></Route>
                  <Route path={`/admin/reports/users`} element={<ValidateToken><ReportUsers/></ValidateToken>}></Route>
                  <Route path={`/admin/reports/streamers`} element={<ValidateToken><ReportStreamer/></ValidateToken>}></Route>
                  <Route path={`/admin/reports/streamers/:streamerId`} element={<ValidateToken><ReportStreamerDetail/></ValidateToken>}></Route>
                  <Route path={`/admin/reports/deposit-withdraw`} element={<ValidateToken><ReportDepositWithdraw/></ValidateToken>}></Route>
                  <Route path={`/admin/payment-providers`} element={<ValidateToken><PaymentProvider/></ValidateToken>}></Route>
                  <Route path={`/admin/betting/betting-body`} element={<ValidateToken><BettingBody/></ValidateToken>}></Route>
                  <Route path={`/admin/betting/betting-parlay`} element={<ValidateToken><BettingParlay/></ValidateToken>}></Route>
                  <Route path={`/admin/banner`} element={<ValidateToken><Banner/></ValidateToken>}></Route>
                  <Route path={`/admin/caption`} element={<ValidateToken><Caption/></ValidateToken>}></Route>
                  <Route path={`/admin/providers`} element={<ValidateToken><Providers/></ValidateToken>}></Route>
                  <Route path={`/admin/game-types`} element={<ValidateToken><GameTypes/></ValidateToken>}></Route>
                  <Route path={`/admin/game-connect`} element={<ValidateToken><GameConnect/></ValidateToken>}></Route>
                  <Route path={`/admin/games`} element={<ValidateToken><Games/></ValidateToken>}></Route>
                  <Route path={`/admin/games-list`} element={<ValidateToken><GamesList/></ValidateToken>}></Route>
                  <Route path={`/admin/popup`} element={<ValidateToken><PopupBox/></ValidateToken>}></Route>
                  {/* <Route path={`/admin/contact`} element={<ValidateToken><ContactUs/></ValidateToken>}></Route> */}
                  <Route path={`/admin/promotions`} element={<ValidateToken><Promotions/></ValidateToken>}></Route>
                  <Route path={`/admin/promotions/users`} element={<ValidateToken><PromotionUsers/></ValidateToken>}></Route>
                  <Route path={`/admin/promotions/users/:promotionId/:promotionName`} element={<ValidateToken><PromotionUsersDetail/></ValidateToken>}></Route>
                </>
              }
              {
                role === "super" &&
                <>
                  <Route path={`/admin/reports/streamers`} element={<ValidateToken><ReportStreamer/></ValidateToken>}></Route>
                  <Route path={`/admin/promotions/users`} element={<ValidateToken><PromotionUsers/></ValidateToken>}></Route>
                  <Route path={`/admin/promotions/users/:promotionId/:promotionName`} element={<ValidateToken><PromotionUsersDetail/></ValidateToken>}></Route>
                </>
              }
              {
                (role === 'staff' || role === 'super') &&
                <>
                  <Route path="/admin/dashboard" element={<ValidateToken><Dashboard/></ValidateToken>}></Route>
                  <Route path={`/admin/create`} element={<ValidateToken><UserCreate/></ValidateToken>}></Route>
                  <Route path={`/admin/create-streamer`} element={<ValidateToken><CreateStreamer/></ValidateToken>}></Route>
                  <Route path={`/admin/deposit`} element={<ValidateToken><Deposit/></ValidateToken>}></Route>
                  <Route path={`/admin/withdraw`} element={<ValidateToken><Withdraw/></ValidateToken>}></Route>
                  <Route path={`/admin/payment-providers`} element={<ValidateToken><PaymentProvider/></ValidateToken>}></Route>
                  <Route path={`/admin/providers`} element={<ValidateToken><Providers/></ValidateToken>}></Route>
                  <Route path={`/admin/manual-deposit`} element={<ValidateToken><ManualDeposit/></ValidateToken>}></Route>
                  <Route path={`/admin/manual-withdraw`} element={<ValidateToken><ManualWithdraw/></ValidateToken>}></Route>
                  {
                    role === 'staff' && <Route path={`/admin/change-self-password`} element={<ValidateToken><ChangeSelfPassword/></ValidateToken>}></Route>
                  }
                  <Route path={`/admin/games-betting`} element={<ValidateToken><GameBetting/></ValidateToken>}></Route>
                  <Route path={`/admin/transaction-history`} element={<ValidateToken><TransactionHistory/></ValidateToken>}></Route>
                  <Route path={`/admin/balance-transfer`} element={<ValidateToken><BalanceTrans/></ValidateToken>}></Route>
                  <Route path={`/admin/reports/provider`} element={<ValidateToken><ReportProvider/></ValidateToken>}></Route>
                  <Route path={`/admin/reports/users`} element={<ValidateToken><ReportUsers/></ValidateToken>}></Route>
                  <Route path={`/admin/reports/deposit-withdraw`} element={<ValidateToken><ReportDepositWithdraw/></ValidateToken>}></Route>
                  <Route path={`/admin/betting/betting-body`} element={<ValidateToken><BettingBody/></ValidateToken>}></Route>
                  <Route path={`/admin/betting/betting-parlay`} element={<ValidateToken><BettingParlay/></ValidateToken>}></Route>
                </>
              }
              {
                role === 'streamer' &&
                <>
                  <Route path={`/admin/deposit`} element={<ValidateToken><Deposit/></ValidateToken>}></Route>
                  <Route path={`/admin/withdraw`} element={<ValidateToken><Withdraw/></ValidateToken>}></Route>
                  <Route path={`/admin/change-self-password`} element={<ValidateToken><ChangeSelfPassword/></ValidateToken>}></Route>
                </>
              }
              {
                role === 'partner' &&
                <>
                  <Route path={`/admin/manual-deposit`} element={<ValidateToken><ManualDeposit/></ValidateToken>}></Route>
                  <Route path={`/admin/manual-withdraw`} element={<ValidateToken><ManualWithdraw/></ValidateToken>}></Route>
                  <Route path={`/admin/change-self-password`} element={<ValidateToken><ChangeSelfPassword/></ValidateToken>}></Route>
                  <Route path={`/admin/games-betting`} element={<ValidateToken><GameBetting/></ValidateToken>}></Route>
                  <Route path={`/admin/transaction-history`} element={<ValidateToken><TransactionHistory/></ValidateToken>}></Route>
                  <Route path={`/admin/balance-transfer`} element={<ValidateToken><BalanceTrans/></ValidateToken>}></Route>
                  <Route path={`/admin/reports/provider`} element={<ValidateToken><ReportProvider/></ValidateToken>}></Route>
                  <Route path={`/admin/reports/users`} element={<ValidateToken><ReportUsers/></ValidateToken>}></Route>
                  <Route path={`/admin/reports/streamers`} element={<ValidateToken><ReportStreamer/></ValidateToken>}></Route>
                  <Route path={`/admin/reports/deposit-withdraw`} element={<ValidateToken><ReportDepositWithdraw/></ValidateToken>}></Route>
                </>
              }
              <Route path={`/*`} element={<ValidateToken><Dashboard/></ValidateToken>}></Route>
            </Routes>
        </Suspense>
    </Router>
  )
}

export default Routers