import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Row,
  Table,
} from "reactstrap";
import { getMethod } from "../api";
import Breadcrumbs from "../layout/breadcrumb";
import Loader from "../layout/loader/Loader";
import Layout from "./Layout";
import DateRangeFormat from "../utils/DateRangeFormat";
import { calculateTotal, decodeData } from "../utils/Helper";
import { DEPOSIT_WITHDRAW_USER_REPORT_API } from "../api/routes";
import moment from "moment";

const ReportDepositWithdraw = () => {
  const [loading, setLoading] = useState(true);
  const [searchId, setSearchId] = useState("");
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [refr, setRefr] = useState(true);

  useEffect(() => {
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    if (!token) return;
    (async () => {
      setLoading(true);
      console.log(token.token)
      const res = await getMethod(
        `${DEPOSIT_WITHDRAW_USER_REPORT_API}?app_name_id=${
          process.env.REACT_APP_NAME_ID
        }&user_id=${searchId}&start_date=${
          startDate && startDate
        }&end_date=${endDate}`,
        token.token
      );
      res && setLoading(false);
      res?.status === "success" && setData(res.data);
    })();
  }, [refr]);

  const searchSubmitHandler = () => {
    setRefr((prev) => !prev);
  };

  const searchHandler = (e) => {
    setSearchId(e.target.value);
  };

  return (
    <>
      <Layout>
        <Breadcrumbs title="Deposit Withdraw Report" />
        <Container fluid={true}>
        {loading && <Loader />}
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader className="py-4 d-flex justify-content-end">
                  <Col sm="12">
                    <Form>
                      <Row className="justify-content-end">
                        <Col md="4 px-1 mb-2">
                          <Input
                            className="form-control"
                            onChange={searchHandler}
                            type="number"
                            placeholder="Search ID"
                          />
                        </Col>
                        <Col md="4 px-1 mb-2">
                          <DateRangeFormat
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                          />
                        </Col>
                        <Col md="4 px-1 mb-2">
                          <button
                            className="w-100 btn btn-primary"
                            disabled={loading}
                            type="button"
                            onClick={searchSubmitHandler}
                          >
                            {loading ? "Loading..." : "Submit"}
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </CardHeader>
                <CardBody className="position-relative">
                  <div className="table-responsive table-scroll">
                    <Table className="table-border-vertical">
                      <thead>
                        <tr className="bg-dark">
                          <th className="text-center text-white" scope="col">
                            <b>{"ID"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Player"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Pay Account"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"D Count"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"W Count"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Deposit"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Withdraw"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Profit & Loss"}</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.length > 0 &&
                          data?.map((el, i) => (
                            <tr key={i}>
                              <td className="text-center">{el.user_id}</td>
                              <td className="text-center">{el.name}</td>
                              <td className="text-center">
                                {el.payment_provider_name}
                              </td>
                              <td className="text-center">
                                {el.d_count ? el.d_count : 0}
                              </td>
                              <td className="text-center">
                                {el.w_count ? el.w_count : 0}
                              </td>
                              <td className="text-end">
                                {el.d_amount
                                  ? el?.d_amount?.toLocaleString("en-us")
                                  : 0}
                              </td>
                              <td className="text-end">
                                {el.w_amount
                                  ? el?.w_amount?.toLocaleString("en-us")
                                  : 0}
                              </td>
                              <td className="text-end">
                                {el?.profit
                                  ? el?.profit?.toLocaleString("en-us")
                                  : 0}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                      <tfoot>
                        <tr className="bg-dark">
                          <td colSpan={5} className="text-center text-white">
                            Total
                          </td>
                          <td className="text-end text-white">
                            {calculateTotal(data, "d_amount")}
                          </td>
                          <td className="text-end text-white">
                            {calculateTotal(data, "w_amount")}
                          </td>
                          <td className="text-end text-white">
                            {calculateTotal(data, "profit")}
                          </td>
                        </tr>
                      </tfoot>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
};

export default ReportDepositWithdraw;
