import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { deleteMethod, getMethod, postMethodFormData } from "../api";
import Breadcrumbs from "../layout/breadcrumb";
import Layout from "./Layout";
import SweetAlert from "sweetalert2";
import Loader from "../layout/loader/Loader";
import Pagination from "../utils/Pagination";
import TableScroll from "../utils/TableScroll";
import {
  PAYMENT_PROVIDER_API,
  PAYMENT_PROVIDER_DELETE_API,
  PAYMENT_PROVIDER_UPDATE_API,
} from "../api/routes";
import { currentDate, decodeData } from "../utils/Helper";
import ModalImage from "react-modal-image";

const PaymentProvider = () => {
  const [loading, setLoading] = useState(false);
  const [paymentProvider, setPaymentProvider] = useState([]);
  const [refr, setRefr] = useState(true);
  const [editPayment, setEditPayment] = useState(false);
  const [createPayment, setCreatePayment] = useState(false);
  const [paymentName, setPaymentName] = useState("");
  const [paymentImage, setPaymentImage] = useState("");
  const [owner, setOwner] = useState("");
  const [account, setAccount] = useState("");
  const [paymentNameValidate, setPaymentNameValidate] = useState("");
  const [paymentImageValidate, setPaymentImageValidate] = useState("");
  const [ownerValidate, setOwnerValidate] = useState("");
  const [accountValidate, setAccountValidate] = useState("");
  const [provider, setProvider] = useState("");
  const [status, setStatus] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [from, setFrom] = useState(1);
  const [page, setPage] = useState(1);
  const [authRole, setAuthRole] = useState("");

  useEffect(() => {
    (async () => {
      const token = decodeData(localStorage.getItem("y_g_n_d_a"));
      if (!token) return;
      setAuthRole(token.user_role);
      setLoading(true);
      const res = await getMethod(
        `${PAYMENT_PROVIDER_API}?app_name_id=${process.env.REACT_APP_NAME_ID}&page=${page}&limit=100`,
        token.token
      );
      res && setLoading(false);
      if (res?.status === "success") {
        setPaymentProvider(res?.data)
        res?.meta?.from && setFrom(res?.meta?.from);
        res?.meta?.last_page && setFrom(res?.meta?.last_page);
      }
    })();
  }, [refr, page]);

  const editOpenModal = () => {
    setEditPayment(!editPayment);
    setPaymentName("");
    setPaymentImage("");
    setOwner("");
    setAccount("");
  };

  useEffect(() => {
    if (provider) {
      setPaymentName(provider?.name);
      setOwner(provider?.owner);
      setAccount(provider?.phone);
      setStatus(provider?.status);
    }
  }, [provider]);

  const createOpenModal = () => {
    setCreatePayment(!createPayment);
    setPaymentName("");
    setPaymentImage("");
    setOwner("");
    setAccount("");
    setPaymentNameValidate("");
    setPaymentImageValidate("");
    setAccountValidate("");
    setStatus("");
  };

  const statusHandler = (e) => {
    setStatus(e.target.value);
  };

  const createSubmitHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", paymentName);
    formData.append("photo", paymentImage);
    formData.append("owner", owner);
    formData.append("phone", account);
    formData.append("app_name_id", process.env.REACT_APP_NAME_ID);
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    if (!token) return;
    if (!paymentName) {
        setPaymentNameValidate('Payment name is required.')
        return;
    }
    if (!paymentImage) {
        setPaymentImageValidate('Payment image is required.')
        return;
    };
    if (!owner) {
        setOwnerValidate('Owner name is required.')
        return;
    };
    if (!account) {
        setAccountValidate('Account number is required.')
        return;
    };
    setLoading(true);
    const response = await postMethodFormData(
      PAYMENT_PROVIDER_API,
      formData,
      token.token
    );
    response && setLoading(false);
    if (response.status === "success") {
      setRefr(!refr);
      setPaymentName("");
      setPaymentImage("");
      setOwner("");
      setAccount("");
      setCreatePayment(false);
      SweetAlert.fire({
        icon: "success",
        width: 300,
        title: "Success",
        text: response?.message ?? "Payment Provider Create Successfull.",
      });
      return;
    } else {
      SweetAlert.fire({
        icon: "error",
        width: 300,
        title: "Sorry!",
        text:
          (response?.field ?? "") +
          " " +
          (response?.message ?? "Something wrong, Try again!"),
      });
      return;
    }
  };

  const editSubmitHandler = async () => {
    const formData = new FormData();
    formData.append("name", paymentName);
    formData.append("photo", paymentImage);
    formData.append("owner", owner);
    formData.append("phone", account);
    formData.append("status", status);
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    const res = await postMethodFormData(
      `${PAYMENT_PROVIDER_UPDATE_API(provider.id)}?updated_at=${currentDate()}`,
      formData,
      token.token,
      "PUT"
    );
    res && setLoading(false);
    if (res?.status === "success") {
      setCreatePayment(false);
      setRefr(!refr);
      setPaymentName("");
      setPaymentImage("");
      setOwner("");
      setAccount("");
      setEditPayment(false);
      SweetAlert.fire({
        icon: "success",
        width: 300,
        title: "Success",
        text: res?.message ?? "Payment Provider Update Successfull.",
      });
      return;
    } else {
      SweetAlert.fire({
        icon: "error",
        width: 300,
        title: "Sorry!",
        text:
          (res?.field ?? "") +
          " " +
          (res?.message ?? "Something wrong, Try again!"),
      });
      return;
    }
  };

  const deleteHandler = async (id) => {
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    if (!token) return;
    SweetAlert.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      width: 300,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        (async () => {
          const res = await deleteMethod(
            PAYMENT_PROVIDER_DELETE_API(id),
            token.token
          );
          res && setLoading(false);
          if (res.status === "success") {
            SweetAlert.fire({
              title: "Success!",
              text: "You has been deleted.",
              icon: "success",
              width: 300,
            });
            setRefr(!refr);
            return;
          } else {
            SweetAlert.fire({
              title: "Sorry!",
              text: res?.message ?? "Something wrong, Try again!",
              icon: "error",
              width: 300,
            });
            setRefr(!refr);
            return;
          }
        })();
      }
    });
  };

  return (
    <>
      <Layout>
        <Breadcrumbs title="Payment Providers" />
        <Container fluid={true}>
        {loading && <Loader />}
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader className="py-4 d-flex justify-content-end">
                  {
                    authRole === 'admin' &&
                  <Button
                    color="info"
                    className="text-white"
                    onClick={createOpenModal}
                  >
                    Create
                  </Button>
                  }
                </CardHeader>
                <CardBody className="position-relative">
                  <TableScroll>
                    <Table className="table-border-vertical">
                      <thead>
                        <tr className="bg-dark">
                          <th className="text-center text-white" scope="col">
                            <b>{"No."}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Name"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Account"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Owner"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Photo"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Status"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Action"}</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {paymentProvider.length > 0 &&
                          paymentProvider.map((payment, i) => (
                            <tr key={payment.id} className="align-middle">
                              <th className="text-center" scope="row">{`${
                                i + from
                              }.`}</th>
                              <td className="text-center">{payment.name}</td>
                              <td className="text-center">{payment.phone}</td>
                              <td className="text-center">{payment.owner}</td>
                              <td className="text-center">
                                <div
                                  style={{
                                    width: "50px",
                                    borderRadius: "5px",
                                    overflow: "hidden",
                                    margin: "auto",
                                  }}
                                >
                                  <ModalImage
                                    alt={payment.name}
                                    small={payment.photo}
                                    large={payment.photo}
                                  />
                                </div>
                              </td>
                              <td className="text-center">{payment.status === 'active' ? <i className="fa fa-circle font-success f-12" /> : <i className="fa fa-circle font-danger f-12" />}</td>
                              <td className="text-center">
                                <div>
                                  <span
                                    onClick={() => {
                                      editOpenModal();
                                      setProvider(payment);
                                    }}
                                  >
                                    <i
                                      className="fa fa-pencil"
                                      style={{
                                        width: 35,
                                        fontSize: 18,
                                        padding: 11,
                                        color: "rgb(40, 167, 69)",
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  </span>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </TableScroll>
                </CardBody>
                <CardFooter className="d-flex justify-content-end">
                  <Pagination
                    setPage={setPage}
                    totalPage={totalPage}
                    setLoading={setLoading}
                  />
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
      {
        <Modal isOpen={createPayment} toggle={createOpenModal} centered>
          <Form onSubmit={createSubmitHandler}>
            <ModalHeader toggle={createOpenModal}>
              Create Payment Provider
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label htmlFor="">Payment Name</Label>
                <Input
                  className="form-control"
                  type="text"
                  placeholder="Payment Name"
                  onChange={(e) => {
                    setPaymentName(e.target.value);
                    setPaymentNameValidate("");
                  }}
                />
                {paymentNameValidate && (
                  <div className="invalid-feedback d-block">
                    {paymentNameValidate}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">Photo</Label>
                <Input
                  className="form-control"
                  type="file"
                  placeholder=""
                  onChange={(e) => {
                    setPaymentImage(e.target.files[0]);
                    setPaymentImageValidate("");
                  }}
                />
                {paymentImageValidate && (
                  <div className="invalid-feedback d-block">
                    {paymentImageValidate}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">Owner Name</Label>
                <Input
                  className="form-control"
                  type="text"
                  placeholder="Owner Name"
                  onChange={(e) => {
                    setOwner(e.target.value);
                    setOwnerValidate("");
                  }}
                />
                {ownerValidate && (
                  <div className="invalid-feedback d-block">
                    {ownerValidate}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">Phone Number</Label>
                <Input
                  className="form-control"
                  type="text"
                  placeholder="Phone Number"
                  onChange={(e) => {
                    setAccount(e.target.value);
                    setAccountValidate("");
                  }}
                />
                {accountValidate && (
                  <div className="invalid-feedback d-block">
                    {accountValidate}
                  </div>
                )}
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={createOpenModal}>
                Close
              </Button>
              <Button color="primary" type="submit">
                Save
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      }
      {
        <Modal isOpen={editPayment} toggle={editOpenModal} centered>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              editSubmitHandler();
            }}
          >
            <ModalHeader toggle={editOpenModal}>
              Edit Payment Provider
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label htmlFor="">Payment Name</Label>
                <Input
                  className="form-control"
                  defaultValue={paymentName}
                  type="text"
                  placeholder="Payment Name"
                  onChange={(e) => {
                    setPaymentName(e.target.value);
                    setPaymentNameValidate("");
                  }}
                />
                {paymentNameValidate && (
                  <div className="invalid-feedback d-block">
                    {paymentNameValidate}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">Photo</Label>
                <Input
                  className="form-control"
                  type="file"
                  placeholder=""
                  onChange={(e) => {
                    setPaymentImage(e.target.files[0]);
                    setPaymentImageValidate("");
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">Owner Name</Label>
                <Input
                  className="form-control"
                  defaultValue={owner}
                  type="text"
                  placeholder="Owner Name"
                  onChange={(e) => {
                    setOwner(e.target.value);
                    setOwnerValidate("");
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">Payment Account</Label>
                <Input
                  className="form-control"
                  defaultValue={account}
                  type="text"
                  placeholder="Payment Account"
                  onChange={(e) => {
                    setAccount(e.target.value);
                    setAccountValidate("");
                  }}
                />
              </FormGroup>
              <Row>
                <Col>
                  <div className="mb-0">
                    <Label>Payment Status</Label>
                    <Input
                      type="select"
                      name="select"
                      className="form-control form-control-sm digits"
                      onChange={statusHandler}
                      value={status}
                    >
                      <option value="">Choose</option>
                      <option value="active">Active</option>
                      <option value="disable">Disabled</option>
                    </Input>
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={editOpenModal}>
                Close
              </Button>
              <Button color="primary" type="submit">
                Save
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      }
    </>
  );
};

export default PaymentProvider;
