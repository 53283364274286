import React, { useState } from 'react'
import { Spinner } from 'reactstrap'
import { getMethodAuth } from '../api';
import { decodeData } from './Helper';
import { STREAMER_INFO_API, USER_INFO_API } from '../api/routes';

const UserBalance = ({status,id,role}) => {
  const [userBalance,setUserBalance] = useState(0);
  const [balanceLoader,setBalanceLoader] = useState(false);

  const balanceShowHandler = async id => {
    setBalanceLoader(true);
    const token = decodeData(localStorage.getItem('y_g_n_d_a'));
    if(token){
        let res;
        switch (role) {
            case 'streamer':
                res = await getMethodAuth(`${STREAMER_INFO_API}/${id}`, token.token);
                break;
            default:
                res = await getMethodAuth(`${USER_INFO_API}/${id}`, token.token);
                break;
        }
        res && setBalanceLoader(false);
        if(res?.status === 'success'){
            if(res.data?.balance === 0){
                setUserBalance("0")
            }else{
                setUserBalance(res.data?.balance);
            };
        };
    }else{
        setBalanceLoader(false);
    }
};
const chunk = num => {
    let str = num.toString().split('.');
    if (str[0].length >= 4) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    if (str[1] && str[1].length >= 5) {
        str[1] = str[1].replace(/(\d{3})/g, '$1 ');
    }
    return str.join('.');
};

  return (
    status === "Active"?
    balanceLoader? 
    <Spinner type="grow" style={{ width: '20px', height: '20px',color: '#7366ff' }}/> :

    (userBalance === 0 ?
    // <span style={{color: '#7366ff', cursor: 'pointer', fontWeight: '300'}} onClick={()=>balanceShowHandler(id)}>show</span>
    <>
     <div className='d-flex justify-content-end align-items-center'>
        <span className='mt-1'>******</span> &nbsp;&nbsp;
        <i className="fa fa-eye-slash" style={{color: '#7366ff', cursor: 'pointer', fontSize: '18px'}} onClick={()=>balanceShowHandler(id)}/>
     </div>
    </>

    :
        chunk(userBalance))
    :
    0
  )
}

export default UserBalance
