import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { deleteMethod, getMethod, postMethodFormData } from "../api";
import Breadcrumbs from "../layout/breadcrumb";
import Layout from "./Layout";
import SweetAlert from "sweetalert2";
import Loader from "../layout/loader/Loader";
import DateRangeFormat from "../utils/DateRangeFormat";
import TableScroll from "../utils/TableScroll";
import { currentDate, decodeData } from "../utils/Helper";
import {
  PROMOTION_API,
  PROMOTION_CREATE_API,
  PROMOTION_DELETE_API,
  PROMOTION_UPDATE_API,
} from "../api/routes";
import ModalImage from "react-modal-image";

const Promotions = () => {
  const [loading, setLoading] = useState(false);
  const [promotions, setPromotions] = useState([]);
  const [refr, setRefr] = useState(true);
  const [editPromotion, setEditPromotion] = useState(false);
  const [createPromotion, setCreatePromotion] = useState(false);
  const [promotionImage, setPromotionImage] = useState("");
  const [promotionEnTitle, setPromotionEnTitle] = useState("");
  const [promotionMmTitle, setPromotionMmTitle] = useState("");
  const [promotionDescription, setPromotionDescription] = useState("");
  const [promotionFrom, setPromotionFrom] = useState("");
  const [promotionTo, setPromotionTo] = useState("");
  const [promotionImageValidate, setPromotionImageValidate] = useState("");
  const [promotionEnTitleValidate, setPromotionEnTitleValidate] = useState("");
  const [promotionMmTitleValidate, setPromotionMmTitleValidate] = useState("");
  const [promotionFromValidate, setPromotionFromValidate] = useState("");
  const [promotionToValidate, setPromotionToValidate] = useState("");
  const [promotionDescriptionValidate, setPromotionDescriptionValidate] =
    useState("");
  const [paymentImageValidate, setPaymentImageValidate] = useState("");
  const [promotionEditId, setPromotionEditId] = useState("");
  const [showDetail, setShowDetail] = useState(false);
  const [detailData, setDetailData] = useState('');

  useEffect(() => {
    (async () => {
      const token = decodeData(localStorage.getItem("y_g_n_d_a"));
      if (!token) return;
      setLoading(true);
      const res = await getMethod(
        `${PROMOTION_API}?app_name_id=${process.env.REACT_APP_NAME_ID}&type=all`, token.token
      );
      res && setLoading(false);
      res?.status === "success" && setPromotions(res?.data);
    })();
  }, [refr]);

  const createOpenModal = () => {
    setCreatePromotion(!createPromotion);
    setPromotionEnTitle("");
    setPromotionMmTitle("");
    setPromotionFrom("");
    setPromotionTo("");
    setPromotionDescription("");
    setPromotionEnTitleValidate("");
    setPromotionMmTitleValidate("");
    setPromotionFromValidate("");
    setPromotionToValidate("");
    setPromotionDescriptionValidate("");
  };

  const editOpenModal = (data = {}) => {
    setEditPromotion(!editPromotion);
    setPromotionEnTitle(data?.en_title ?? "");
    setPromotionMmTitle(data?.mm_title ?? "");
    setPromotionFrom(data?.from ?? "");
    setPromotionTo(data?.to ?? "");
    setPromotionDescription(data?.description ?? "");
  };

  //   for create promotion handler
  const createSubmitHandler = async (e) => {
    e.preventDefault();
    if (!promotionImage) {
      setPromotionImageValidate("Promotion photo is required.");
      return;
    }
    if (!promotionEnTitle) {
      setPromotionEnTitleValidate("Promotion en title is required.");
      return;
    }
    if (!promotionMmTitle) {
      setPromotionMmTitleValidate("Promotion mm title is required.");
      return;
    }
    if (!promotionFrom) {
      setPromotionFromValidate("Promotion start date is required.");
      return;
    }
    if (!promotionTo) {
      setPromotionToValidate("Promotion end date is required.");
      return;
    }
    const formData = new FormData();
    formData.append("photo", promotionImage);
    formData.append("en_title", promotionEnTitle);
    formData.append("mm_title", promotionMmTitle);
    formData.append("from", promotionFrom);
    formData.append("to", promotionTo);
    formData.append("description", promotionDescription);
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    if (!token) return;
    setLoading(true);
    const res = await postMethodFormData(
      PROMOTION_CREATE_API,
      formData,
      token.token
    );
    res && setLoading(false);
    if (res?.status === "success") {
      setCreatePromotion(false);
      setRefr(!refr);
      setPromotionImage("");
      setPromotionEnTitle("");
      setPromotionMmTitle("");
      setPromotionFrom("");
      setPromotionTo("");
      setPromotionDescription("");
      return;
    } else {
      SweetAlert.fire({
        icon: "error",
        width: 300,
        title: "Sorry",
        text:
          (res?.field ?? "") +
          " " +
          (res?.message ?? "Something wrong, Try again!"),
      });
      return;
    }
  };

  //   for edit promotion handler
  const editSubmitHandler = async (e) => {
    e.preventDefault();
    if (!promotionEditId) return;
    const formData = new FormData();
    formData.append("photo", promotionImage);
    formData.append("en_title", promotionEnTitle);
    formData.append("mm_title", promotionMmTitle);
    formData.append("from", promotionFrom);
    formData.append("to", promotionTo);
    formData.append("description", promotionDescription);
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    if (!token) return;
    setLoading(true);
    const res = await postMethodFormData(
      `${PROMOTION_UPDATE_API(promotionEditId)}?updated_at=${currentDate()}`,
      formData,
      token.token,
      'PUT'
    );
    res && setLoading(false);
    if (res?.status === "success") {
      editOpenModal();
      setRefr(!refr);
    } else {
      SweetAlert.fire({
        icon: "error",
        width: 300,
        title: "Sorry",
        text:
          (res?.field ?? "") +
          " " +
          (res?.message ?? "Something wrong, Try again!"),
      });
      return;
    }
  };

  // for promotion delete handler
  const deleteHandler = async (id) => {
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    if (!token) return;
    SweetAlert.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      width: 300,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        (async () => {
          const res = await deleteMethod(PROMOTION_DELETE_API(id), token.token);
          res && setLoading(false);
          if (res?.status === "success") {
            SweetAlert.fire({
              title: "Success!",
              text: "You has been deleted.",
              icon: "success",
              width: 300,
            });
            setRefr(!refr);
            return;
          } else {
            SweetAlert.fire({
              title: "Sorry!",
              text: res?.message ?? "Something wrong, Try again!",
              icon: "error",
              width: 300,
            });
            return;
          }
        })();
      }
    });
  };

  const popupToggler = async (edit_id, status) => {
    const formData = new FormData();
    formData.append("status", status === "active" ? "disable" : "active");
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    if (!token) return;
    setLoading(true);
    const res = await postMethodFormData(
      `${PROMOTION_UPDATE_API(edit_id)}?updated_at=${currentDate()}`,
      formData,
      token.token,
      "PUT"
    );
    res && setLoading(false);
    if (res?.status === "success") {
      setRefr(!refr);
      return;
    } else {
      SweetAlert.fire({
        icon: "error",
        width: 300,
        title: "Sorry!",
        text: res?.message ?? "Something wrong, Try again!",
      });
      return;
    }
  };

  return (
    <>
      <Layout>
        <Breadcrumbs title="Promotions" />
        <Container fluid={true}>
        {loading && <Loader />}
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader className="py-4 d-flex justify-content-end">
                  <Button
                    color=""
                    className="text-white btn btn-primary"
                    onClick={createOpenModal}
                  >
                    Create
                  </Button>
                </CardHeader>
                <CardBody className="position-relative">
                  <TableScroll>
                    <Table className="table-border-vertical">
                      <thead>
                        <tr className="bg-dark">
                          <th className="text-center text-white" scope="col">
                            <b>{"No."}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Photo"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"EN_Title"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"MM_Title"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Description"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"FROM"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"TO"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Status"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Action"}</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {promotions?.length > 0 &&
                          promotions?.map((promotion, i) => (
                            <tr key={promotion.id} className="align-middle">
                              <th className="text-center" scope="row">{`${
                                i + 1
                              }.`}</th>
                              <td className="text-center">
                                <div
                                  style={{
                                    width: "75px",
                                    borderRadius: "5px",
                                    overflow: "hidden",
                                  }}
                                >
                                  <ModalImage
                                    alt={promotion.en_title}
                                    small={promotion.photo}
                                    large={promotion.photo}
                                  />
                                </div>
                              </td>
                              <td className="text-center">
                                {promotion.en_title}
                              </td>
                              <td className="text-center">
                                {promotion.mm_title}
                              </td>
                              <td title="Click For Detail" className="text-center hover-underline" style={{cursor: 'pointer'}} onClick={e => {setShowDetail(true); setDetailData(promotion.description)}}>
                                {promotion.description.slice(0,50)} ...
                              </td>
                              <td className="text-center">{promotion.from}</td>
                              <td className="text-center">{promotion.to}</td>
                              <td className="text-center">
                                <FormGroup switch>
                                  <Input
                                    type="switch"
                                    className="bg-primary"
                                    style={{
                                      height: "20px",
                                      width: "35px",
                                      userSelect: "none",
                                      cursor: "pointer",
                                    }}
                                    checked={promotion?.status === "active" ? true : false}
                                    onClick={() => {
                                      popupToggler(promotion?.id, promotion?.status)
                                    }}
                                  />
                                </FormGroup>
                              </td>
                              <td className="text-center">
                                <div>
                                  <span
                                    onClick={() => {
                                      editOpenModal(promotion);
                                      setPromotionEditId(promotion.id);
                                    }}
                                  >
                                    <i
                                      className="fa fa-pencil"
                                      style={{
                                        width: 35,
                                        fontSize: 18,
                                        padding: 11,
                                        color: "rgb(40, 167, 69)",
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  </span>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </TableScroll>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
      {
        <Modal isOpen={createPromotion} toggle={createOpenModal} centered>
          <Form onSubmit={createSubmitHandler}>
            <ModalHeader toggle={createOpenModal}>Create Promotion</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label htmlFor="">Photo ( Size = 1080 x 640 )</Label>
                <Input
                  className="form-control"
                  type="file"
                  onChange={(e) => {
                    setPromotionImage(e.target.files[0]);
                    setPaymentImageValidate("");
                  }}
                />
                {promotionImageValidate && (
                  <div className="invalid-feedback d-block">
                    {promotionImageValidate}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">En Title</Label>
                <Input
                  className="form-control"
                  type="text"
                  placeholder="En Title"
                  onChange={(e) => {
                    setPromotionEnTitle(e.target.value);
                    setPromotionEnTitleValidate("");
                  }}
                />
                {promotionEnTitleValidate && (
                  <div className="invalid-feedback d-block">
                    {promotionEnTitleValidate}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">Mm Title</Label>
                <Input
                  className="form-control"
                  type="text"
                  placeholder="Mm Title"
                  onChange={(e) => {
                    setPromotionMmTitle(e.target.value);
                    setPromotionMmTitleValidate("");
                  }}
                />
                {promotionMmTitleValidate && (
                  <div className="invalid-feedback d-block">
                    {promotionMmTitleValidate}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">From - To</Label>
                <DateRangeFormat
                  setStartDate={setPromotionFrom}
                  setEndDate={setPromotionTo}
                />
                {promotionFromValidate && (
                  <div className="invalid-feedback d-block">
                    {promotionFromValidate}
                  </div>
                )}
                {promotionToValidate && (
                  <div className="invalid-feedback d-block">
                    {promotionToValidate}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">Description</Label>
                <textarea
                  className="form-control"
                  placeholder="Description"
                  rows={8}
                  onChange={(e) => {
                    setPromotionDescription(e.target.value);
                    setPromotionDescriptionValidate("");
                  }}
                ></textarea>
                {promotionDescriptionValidate && (
                  <div className="invalid-feedback d-block">
                    {promotionDescriptionValidate}
                  </div>
                )}
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={createOpenModal}>
                Close
              </Button>
              <Button color="primary" type="submit">
                Save
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      }
      {
        <Modal isOpen={editPromotion} toggle={editOpenModal} centered>
          <Form onSubmit={editSubmitHandler}>
            <ModalHeader toggle={editOpenModal}>Edit Promotion</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label htmlFor="">Photo ( Size = 1080 x 640 )</Label>
                <Input
                  className="form-control"
                  type="file"
                  onChange={(e) => {
                    setPromotionImage(e.target.files[0]);
                    setPaymentImageValidate("");
                  }}
                />
                {paymentImageValidate && (
                  <div className="invalid-feedback d-block">
                    {paymentImageValidate}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">En Title</Label>
                <Input
                  className="form-control"
                  type="text"
                  value={promotionEnTitle}
                  placeholder="En Title"
                  onChange={(e) => {
                    setPromotionEnTitle(e.target.value);
                    setPromotionEnTitleValidate("");
                  }}
                />
                {promotionEnTitleValidate && (
                  <div className="invalid-feedback d-block">
                    {promotionEnTitleValidate}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">Mm Title</Label>
                <Input
                  className="form-control"
                  type="text"
                  value={promotionMmTitle}
                  placeholder="Mm Title"
                  onChange={(e) => {
                    setPromotionMmTitle(e.target.value);
                    setPromotionMmTitleValidate("");
                  }}
                />
                {promotionMmTitleValidate && (
                  <div className="invalid-feedback d-block">
                    {promotionMmTitleValidate}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">From - To</Label>
                <DateRangeFormat
                  setStartDate={setPromotionFrom}
                  setEndDate={setPromotionTo}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">Description</Label>
                <textarea
                  className="form-control"
                  value={promotionDescription}
                  rows={8}
                  placeholder="Description"
                  onChange={(e) => {
                    setPromotionDescription(e.target.value);
                    setPromotionDescriptionValidate("");
                  }}
                ></textarea>
                {promotionDescriptionValidate && (
                  <div className="invalid-feedback d-block">
                    {promotionDescriptionValidate}
                  </div>
                )}
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={editOpenModal}>
                Close
              </Button>
              <Button color="primary" type="submit">
                Save
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      }
      {
        <Modal isOpen={showDetail} toggle={e => {setShowDetail(false); setDetailData('')}} centered>
            <ModalHeader toggle={e => {setShowDetail(false); setDetailData('')}}>Description Detail</ModalHeader>
            <ModalBody>
              <p>
                {detailData}
              </p>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={e => {setShowDetail(false); setDetailData('')}}>
                Close
              </Button>
            </ModalFooter>
        </Modal>
      }
    </>
  );
};

export default Promotions;
