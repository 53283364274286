import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Row,
  Table,
} from "reactstrap";
import { getMethod } from "../api";
import Breadcrumbs from "../layout/breadcrumb";
import Loader from "../layout/loader/Loader";
import Pagination from "../utils/Pagination";
import Layout from "./Layout";
import DateRangeFormat from "../utils/DateRangeFormat";
import TableScroll from "../utils/TableScroll";
import { BALANCE_TRANSFER_REPORT_API } from "../api/routes";
import {
  calculateNetProfit,
  calculateTotal,
  decodeData,
} from "../utils/Helper";
import moment from "moment";

const BalanceTrans = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [from, setFrom] = useState(1);
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState("");
  const [searchId, setSearchId] = useState("");
  const [searchName, setSearchName] = useState("");
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [refr, setRefr] = useState(true);

  useEffect(() => {
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    if (!token) return;
    setLoading(true);
    (async () => {
      const res = await getMethod(
        `${BALANCE_TRANSFER_REPORT_API}?start_date=${startDate}&end_date=${endDate}&app_name_id=${process.env.REACT_APP_NAME_ID}&user_id=${searchId}&name=${searchName}&status=${status}&page=${page}`,
        token.token
      );
      res && setLoading(false)
      if (res?.status === "success") {
        setData(res?.data);
        res?.meta?.from && setFrom(res?.meta?.from);
        res?.meta?.last_page && setTotalPage(res?.meta?.last_page);
      }
    })();
  }, [refr, page]);

  const searchSubmitHandler = () => {
    setRefr((prev) => !prev);
  };

  const searchIdHandler = (e) => {
    setSearchId(e.target.value);
  };
  const searchHandler = (e) => {
    setSearchName(e.target.value);
  };

  const statusHandler = (e) => {
    setStatus(e.target.value);
  };

  return (
    <>
      <Layout>
        <Breadcrumbs title="Balance Transfer" />
        <Container fluid={true}>
          {loading && <Loader />}
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader className="py-4 d-flex justify-content-end">
                  <Col sm="12">
                    <Form>
                      <Row className="justify-content-end">
                        <Col md="4 px-1 mb-2">
                          <Input
                            className="form-control"
                            onChange={searchIdHandler}
                            type="number"
                            placeholder="Search ID"
                          />
                        </Col>
                        <Col md="4 px-1 mb-2">
                          <Input
                            className="form-control"
                            onChange={searchHandler}
                            type="text"
                            placeholder="Search Name"
                          />
                        </Col>
                        <Col md="4 px-1 mb-2">
                          <Input
                            type="select"
                            name="select"
                            className="form-control"
                            onChange={statusHandler}
                          >
                            <option value="">Select Status</option>
                            <option value="increase">Deposit</option>
                            <option value="decrease">Withdraw</option>
                          </Input>
                        </Col>
                        <Col md="4 px-1 mb-2">
                          <DateRangeFormat
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                          />
                        </Col>
                        <Col md="4 px-1 mb-2">
                          <button
                            className="w-100 btn text-white btn-primary"
                            disabled={loading}
                            type="button"
                            onClick={searchSubmitHandler}
                          >
                            {loading ? "Loading..." : "Submit"}
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </CardHeader>
                <CardBody className="position-relative">
                  <TableScroll>
                    <Table className="table-border-vertical">
                      <thead>
                        <tr
                          className="bg-dark"
                          style={{ verticalAlign: "middle" }}
                        >
                          <th className="text-center text-white" scope="col">
                            <b>No.</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Name</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>ID</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Initial Balance</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Transfer Balance</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Final Balance</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Status</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Remark</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Operator</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Date</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.length > 0 &&
                          data?.map((el, i) => (
                            <tr key={i}>
                              <th className="text-center" scope="row">{`${
                                i + from
                              }.`}</th>
                              <td className="text-center">{el.user_name}</td>
                              <td className="text-center">{el.user_id}</td>
                              <td style={{ textAlign: "right" }}>
                                {el.initial_balance
                                  ? el.initial_balance?.toLocaleString("en-us")
                                  : 0}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {el.transfer_balance
                                  ? el.transfer_balance?.toLocaleString("en-us")
                                  : 0}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {el.final_balance
                                  ? el.final_balance?.toLocaleString("en-us")
                                  : 0}
                              </td>
                              <td
                                className={`text-center ${
                                  el.status === "decrease"
                                    ? "text-danger"
                                    : "text-success"
                                }`}
                              >
                                {el.status === "decrease" ? "Out" : "In"}
                              </td>
                              <td className="text-center">{el.remark}</td>
                              <td className="text-center">
                                {el.receive_name}
                              </td>
                              <td className="text-center">{el.created_at}</td>
                            </tr>
                          ))}
                      </tbody>
                      <tfoot>
                        <tr className="bg-dark">
                          <td colSpan={3} className="text-center text-white">
                            Total
                          </td>
                          <td className="text-end text-white">
                            {calculateTotal(data, "initial_balance")}
                          </td>
                          <td className="text-end text-white">
                            {calculateNetProfit(
                              data,
                              "status",
                              "increase",
                              "transfer_balance"
                            )}
                          </td>
                          <td className="text-end text-white">
                            {calculateTotal(data, "final_balance")}
                          </td>
                          <td colSpan={4} className="text-end text-white"></td>
                        </tr>
                      </tfoot>
                    </Table>
                  </TableScroll>
                </CardBody>
                <CardFooter className="d-flex justify-content-end">
                  <Pagination
                    setPage={setPage}
                    totalPage={totalPage}
                    setLoading={setLoading}
                  />
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
};

export default BalanceTrans;
