import CryptoJS from "crypto-js";
import moment from "moment";

export const calculateTotal = (data, name = "amount") => {
  if (data?.length > 0) {
    return data
      .reduce((total, item) => total + +item[name], 0)
      .toLocaleString("en-us")
  } else {
    return 0
  }
};

export const calculateProfit = (data, first = "amount", second = "amount") => {
  if (data?.length > 0) {
    return data
      .reduce((total, item) => total + (item[first] - item[second]), 0)
      .toLocaleString("en-us")
  } else {
    return 0
  }
};

export const calculateNetProfit = (
  data,
  target_key = "status",
  target_value = "increase",
  name = "amount"
) => {
  if (data?.length > 0) {
    return data
      .reduce((total, item) => {
        return item[target_key] === target_value
          ? total + +item[name]
          : total - +item[name]
      }, 0)
      .toLocaleString("en-us")
  } else {
    return 0
  }
};

export const numericeValueFilter = (input) => {
  const numericValue = input.replace(/\D/g, "");
  return numericValue;
};

export const encodeData = (data) => {
  const deText = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    process.env.REACT_APP_SECRET_KEY
  ).toString();
  return deText;
};

export const decodeData = (data) => {
  try {
    const bytes = CryptoJS.AES.decrypt(data, process.env.REACT_APP_SECRET_KEY);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  } catch (err) {
    localStorage.removeItem("y_g_n_d_a");
  }
};

export const relativeTime = (inputDate) => {
  return moment(inputDate).fromNow();
};

export const timeStampFormatter = (input_date) => {
  return moment(input_date).format('MMM D, YYYY h:mm A');
}

export const currentDate = () => moment().format("YYYY-MM-DD HH:mm:ss")