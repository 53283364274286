import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { deleteMethod, getMethod, postMethodFormData } from "../api";
import Breadcrumbs from "../layout/breadcrumb";
import Layout from "./Layout";
import Loader from "../layout/loader/Loader";
import TableScroll from "../utils/TableScroll";
import {
  PROVIDER_API,
  PROVIDER_CONNECT_LIST_API,
  PROVIDER_CONNECT_LIST_DELETE_API,
  PROVIDER_CONNECT_LIST_UPDATE_API,
  PROVIDER_TYPE_API,
} from "../api/routes";
import { decodeData } from "../utils/Helper";
import SweetAlert from "sweetalert2";
import ModalImage from "react-modal-image";

const GameConnect = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [providers, setProviders] = useState([]);
  const [providersList, setProvidersList] = useState([]);
  const [refr, setRefr] = useState(true);
  const [editGameConnect, setEditGameConnect] = useState(false);
  const [createGameConnect, setCreateGameConnect] = useState(false);
  const [providerName, setProviderName] = useState("");
  const [providerCode, setProviderCode] = useState("");
  const [providerType, setProviderType] = useState("");
  const [providerTypeImage, setProviderTypeImage] = useState("");
  const [providerNameValidate, setProviderNameValidate] = useState("");
  const [providerCodeValidate, setProviderCodeValidate] = useState("");
  const [providerTypeValidate, setProviderTypeValidate] = useState("");
  const [providerTypeImageValidate, setProviderTypeImageValidate] =
    useState("");
  const [gameEditid, setGameEditid] = useState("");
  const [status, setStatus] = useState("");

  useEffect(() => {
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    if (!token) return;
    (async () => {
        setLoading(true);
      const res = await getMethod(`${PROVIDER_CONNECT_LIST_API}?website=dashboard`, token.token);
      res && setLoading(false);
      res?.status === "success" && setData(res?.data);

      const resProvider = await getMethod(`${PROVIDER_API}`);
      resProvider?.status === "success" && setProviders(resProvider?.data);

      const resProviderType = await getMethod(PROVIDER_TYPE_API);
      resProviderType?.status === "success" &&
        setProvidersList(resProviderType?.data);
    })();
  }, [refr]);

  const editOpenModal = (data = {}) => {
    setEditGameConnect(!editGameConnect);
    if (data?.provider_type) {
      setProviderName(data?.provider.id ?? '');
    } else {
      setProviderName('');
    }
    setProviderCode(data?.provider_type?.id ?? '');
    setProviderType(data?.type ?? '');
    setProviderTypeImage("");
    setProviderNameValidate("");
    setProviderCodeValidate("");
    setProviderTypeValidate("");
    setProviderTypeImageValidate("");
    setStatus(data?.status ?? '');
  };

  const createOpenModal = () => {
    setCreateGameConnect(!createGameConnect);
    setProviderName("");
    setProviderCode("");
    setProviderType("");
    setProviderTypeImage("");
    setProviderNameValidate("");
    setProviderCodeValidate("");
    setProviderTypeValidate("");
    setProviderTypeImageValidate("");
    setStatus("");
  };

  const statusHandler = (e) => {
    setStatus(e.target.value);
  };

  const providerNameHandler = (e) => {
    setProviderName(e.target.value);
    setProviderNameValidate("");
  };

  const providerCodeHandler = (e) => {
    setProviderCode(e.target.value);
    setProviderCodeValidate("");
  };

  const providerTypeHandler = (e) => {
    setProviderType(e.target.value);
    setProviderTypeValidate("");
  };

  const createSubmitHandler = async (e) => {
    e.preventDefault();
    if (!providerName) {
      setProviderNameValidate("Provider name is required.");
      return;
    }
    if (!providerCode) {
      setProviderCodeValidate("Provider code is required.");
      return;
    }
    if (!providerType) {
      setProviderTypeValidate("Provider type is required.");
      return;
    }
    if (!providerTypeImage) {
      setProviderTypeImageValidate("Provider type image is required.");
      return;
    }
    const formData = new FormData();
    formData.append("provider_id", providerName);
    formData.append("provider_type_id", providerCode);
    formData.append("type", providerType);
    formData.append("provider_type_photo", providerTypeImage);
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    if (!token) return;
    setLoading(true);
    const res = await postMethodFormData(
      PROVIDER_CONNECT_LIST_API,
      formData,
      token.token
    );
    res && setLoading(false);
    if (res?.status === "success") {
      setLoading(false);
      setCreateGameConnect(false);
      setRefr(!refr);
      setProviderName("");
      setProviderType("");
      setProviderCode("");
      setProviderTypeImage("");
      return;
    } else {
      SweetAlert.fire({
        icon: "error",
        title: "Sorry!",
        text:
          (res?.field ?? "") +
          " " +
          (res?.message ?? "Something wrong, Try again!"),
      });
      return;
    }
  };
  const editSubmitHandler = async (e) => {
    if (!gameEditid) return;
    const formData = new FormData();
    formData.append("provider_id", providerName);
    formData.append("provider_type_id", providerCode);
    formData.append("type", providerType);
    formData.append("provider_type_photo", providerTypeImage);
    formData.append("status", status);
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    if (!token) return;
    setLoading(true);
    const res = await postMethodFormData(
      PROVIDER_CONNECT_LIST_UPDATE_API(gameEditid),
      formData,
      token.token,
      'PUT'
    );
    res && setLoading(false);
    if (res?.status === "success") {
      setLoading(false);
      setEditGameConnect(false);
      setRefr(!refr);
      setProviderName("");
      setProviderType("");
      setProviderCode("");
      setProviderTypeImage("");
      return;
    } else {
      SweetAlert.fire({
        icon: "error",
        title: "Sorry!",
        text:
          (res?.field ?? "") +
          " " +
          (res?.message ?? "Something wrong, Try again!"),
      });
      return;
    }
  };

  const deleteHandler = async (id) => {
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    if (!token) return;
    SweetAlert.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      width: 300,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        (async () => {
          const res = await deleteMethod(
            PROVIDER_CONNECT_LIST_DELETE_API(id),
            token.token
          );
          res && setLoading(false);
          if (res?.status === "success") {
            SweetAlert.fire({
              title: "Success!",
              text: "You has been deleted.",
              icon: "success",
              width: 300,
            });
            setRefr(!refr);
            return;
          } else {
            SweetAlert.fire({
              title: "Sorry!",
              text: res?.message ?? "Something wrong, Try again!",
              icon: "error",
              width: 300,
            });
            return;
          }
        })();
      }
    });
  };

  return (
    <>
      <Layout>
        <Breadcrumbs title="Game Connect" />
        <Container fluid={true}>
        {loading && <Loader />}
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader className="py-4 d-flex justify-content-end">
                  <Button
                    color=""
                    className="text-white"
                    style={{ backgroundColor: "#001753" }}
                    onClick={createOpenModal}
                  >
                    Create
                  </Button>
                </CardHeader>
                <CardBody className="position-relative">
                  <TableScroll>
                    <Table className="table-border-vertical">
                      <thead>
                        <tr className="bg-dark">
                          <th className="text-center text-white" scope="col">
                            <b>No.</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Photo</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Provider</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Game Type</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Type</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Status</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Action</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.length > 0 &&
                          data?.map((el, i) => (
                            <tr key={el.id} className="align-middle">
                              <th className="text-center" scope="row">{`${
                                i + 1
                              }.`}</th>
                              <td className="text-center">
                                  <div
                                  style={{
                                    width: "50px",
                                    borderRadius: "5px",
                                    overflow: "hidden",
                                    margin: "auto",
                                  }}
                                >
                                  <ModalImage
                                    alt={el?.full_name}
                                    small={el?.provider_type?.photo}
                                    large={el?.provider_type?.photo}
                                  />
                                </div>
                              </td>
                              <th className="text-center">
                                {el?.provider?.full_name}
                              </th>
                              <td className="text-center">
                                {el?.provider_type?.name}
                              </td>
                              <td className="text-center">
                                {el?.type}
                              </td>
                              <td className="text-center">
                                {el?.status === "active" ? (
                                  <i className="fa fa-circle font-success f-12" />
                                ) : (
                                  <i className="fa fa-circle font-danger f-12" />
                                )}
                              </td>
                              <td className="text-center">
                                <div>
                                  <span
                                    onClick={() => {
                                      editOpenModal(el);
                                      setGameEditid(el.id);
                                    }}
                                  >
                                    <i
                                      className={`fa fa-pencil font-success`}
                                      style={{
                                        width: 35,
                                        fontSize: 18,
                                        padding: 11,
                                        color: "rgb(40, 167, 69)",
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  </span>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </TableScroll>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
      {
        <Modal isOpen={createGameConnect} toggle={createOpenModal} centered>
          <Form onSubmit={createSubmitHandler}>
            <ModalHeader toggle={createOpenModal}>
              Create Connect Game
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label htmlFor="">Provider Name</Label>
                <Input
                  type="select"
                  name="select"
                  className="form-control"
                  onChange={providerNameHandler}
                >
                  <option value="">Choose</option>
                  {providers?.length > 0 &&
                    providers?.map((el, i) => (
                      <option key={i} value={el.id}>
                        {el.full_name}
                      </option>
                    ))}
                </Input>
                {providerNameValidate && (
                  <div className="invalid-feedback d-block">
                    {providerNameValidate}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">Provider Code</Label>
                <Input
                  type="select"
                  name="select"
                  className="form-control"
                  onChange={providerCodeHandler}
                >
                  <option value="">Choose</option>
                  {providersList?.length > 0 &&
                    providersList?.map((el, i) => (
                      <option key={i} value={el.id}>
                        {el.name}
                      </option>
                    ))}
                </Input>
                {providerCodeValidate && (
                  <div className="invalid-feedback d-block">
                    {providerCodeValidate}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">Type</Label>
                <Input
                  className="form-control"
                  type="text"
                  placeholder="Type"
                  onChange={providerTypeHandler}
                />
                {providerTypeValidate && (
                  <div className="invalid-feedback d-block">
                    {providerTypeValidate}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">Photo</Label>
                <Input
                  className="form-control"
                  type="file"
                  placeholder=""
                  onChange={(e) => {
                    setProviderTypeImage(e.target.files[0]);
                    setProviderTypeImageValidate("");
                  }}
                />
                {providerTypeImageValidate && (
                  <div className="invalid-feedback d-block">
                    {providerTypeImageValidate}
                  </div>
                )}
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={createOpenModal}>
                Close
              </Button>
              <Button color="primary" type="submit">
                Save
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      }
      {
        <Modal isOpen={editGameConnect} toggle={editOpenModal} centered>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              editSubmitHandler();
            }}
          >
            <ModalHeader toggle={editOpenModal}>Edit Connect Game</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label htmlFor="">Provider Name</Label>
                <Input
                  type="select"
                  name="select"
                  className="form-control"
                  onChange={providerNameHandler}
                  value={providerName}
                >
                  <option value="">Choose</option>
                  {providers?.length > 0 &&
                    providers.map((el, i) => (
                      <option key={i} value={el.id}>
                        {el.full_name}
                      </option>
                    ))}
                </Input>
                {providerNameValidate && (
                  <div className="invalid-feedback d-block">
                    {providerNameValidate}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">Provider Code</Label>
                <Input
                  type="select"
                  name="select"
                  className="form-control"
                  onChange={providerCodeHandler}
                  value={providerCode}
                >
                  <option value="">Choose</option>
                  {providersList?.length > 0 &&
                    providersList.map((el, i) => (
                      <option key={i} value={el.id}>
                        {el.name}
                      </option>
                    ))}
                </Input>
                {providerCodeValidate && (
                  <div className="invalid-feedback d-block">
                    {providerCodeValidate}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">Type</Label>
                <Input
                  className="form-control"
                  type="text"
                  placeholder="Type"
                  onChange={providerTypeHandler}
                  value={providerType}
                />
                {providerTypeValidate && (
                  <div className="invalid-feedback d-block">
                    {providerTypeValidate}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">Photo</Label>
                <Input
                  className="form-control"
                  type="file"
                  placeholder=""
                  onChange={(e) => {
                    setProviderTypeImage(e.target.files[0]);
                    setProviderTypeImageValidate("");
                  }}
                />
                {providerTypeImageValidate && (
                  <div className="invalid-feedback d-block">
                    {providerTypeImageValidate}
                  </div>
                )}
              </FormGroup>
              <Row>
                <Col>
                  <div className="mb-0">
                    <Label>Payment Status</Label>
                    <Input
                      type="select"
                      name="select"
                      className="form-control form-control-sm digits"
                      onChange={statusHandler}
                      value={status}
                    >
                      <option value="">Choose</option>
                      <option value="active">Active</option>
                      <option value="disable">Disabled</option>
                    </Input>
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={editOpenModal}>
                Close
              </Button>
              <Button color="primary" type="submit">
                Save
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      }
    </>
  );
};

export default GameConnect;
