import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { getMethod, postMethodFormData } from "../api";
import Breadcrumbs from "../layout/breadcrumb";
import Layout from "./Layout";
import Loader from "../layout/loader/Loader";
import SweetAlert from "sweetalert2";
import TableScroll from "../utils/TableScroll";
import { GAME_API, GAME_UPDATE_API, PROVIDER_API } from "../api/routes";
import { decodeData } from "../utils/Helper";
import ModalImage from "react-modal-image";

const Games = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [providers, setProviders] = useState([]);
  const [refr, setRefr] = useState(true);
  const [editGame, setEditGame] = useState(false);
  const [createGame, setCreateGame] = useState(false);
  const [gameName, setGameName] = useState("");
  const [gameType, setGameType] = useState("");
  const [providerCode, setProviderCode] = useState("");
  const [gameImage, setGameImage] = useState("");
  const [gameNameValidate, setGameNameValidate] = useState("");
  const [gameTypeValidate, setGameTypeValidate] = useState("");
  const [providerCodeValidate, setProviderCodeValidate] = useState("");
  const [gameImageValidate, setGameImageValidate] = useState("");
  const [gameEditid, setGameEditid] = useState("");
  const [status, setStatus] = useState("");

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await getMethod(GAME_API);
      res && setLoading(false);
      if (res.status === "success") {
        setData(res?.data);
      }
      const token = decodeData(localStorage.getItem("y_g_n_d_a"));
      if (!token) return;
      // const resProvider = await getMethod(`${PROVIDER_API}?website=true`, token.token);
      const resProvider = await getMethod(PROVIDER_API);
      if (resProvider.status === "success") {
        setProviders(resProvider.data);
      }
      console.log(res?.data)
    })();
  }, [refr]);

  const editOpenModal = (data = {}) => {
    setEditGame(!editGame);
    setGameName(data?.game_name ?? "");
    setGameType(data?.game_type ?? "");
    setProviderCode(data?.provider_id ?? "");
    setGameImage("");
    setGameNameValidate("");
    setGameTypeValidate("");
    setProviderCodeValidate("");
    setGameImageValidate("");
    setStatus(data?.status ?? "");
  };

  const createOpenModal = () => {
    setCreateGame(!createGame);
    setGameName("");
    setGameType("");
    setProviderCode("");
    setGameImage("");
    setGameNameValidate("");
    setGameTypeValidate("");
    setProviderCodeValidate("");
    setGameImageValidate("");
    setStatus("");
  };

  const statusHandler = (e) => {
    setStatus(e.target.value);
  };

  const gameNameHandler = (e) => {
    setGameName(e.target.value);
    setGameNameValidate("");
  };

  const gameTypeHandler = (e) => {
    setGameType(e.target.value);
    setGameTypeValidate("");
  };

  const providerHandler = (e) => {
    setProviderCode(e.target.value);
    setProviderCodeValidate("");
  };

  const createSubmitHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("game_name", gameName);
    formData.append("game_type", gameType);
    formData.append("provider_id", providerCode);
    formData.append("game_image", gameImage);
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    if (!token) return;
    if (!gameName) {
      setGameNameValidate("Game name is required.");
      return;
    }
    if (!gameType) {
      setGameTypeValidate("Game Type is required.");
      return;
    }
    if (!providerCode) {
      setProviderCodeValidate("Provider code is required.");
      return;
    }
    if (!gameImage) {
      setGameImageValidate("Game image is required.");
      return;
    }
    setLoading(true);
    const res = await postMethodFormData(GAME_API, formData, token.token);
    res && setLoading(false);
    if (res?.status === "success") {
      setCreateGame(false);
      setRefr(!refr);
      setGameName("");
      setGameType("");
      setProviderCode("");
      setGameImage("");
      return;
    } else {
      SweetAlert.fire({
        icon: "error",
        width: 300,
        title: "Sorry!",
        text:
          (res?.field ?? "") +
          " " +
          (res?.message ?? "Something wrong, Try again!"),
      });
      return;
    }
  };
  const editSubmitHandler = async (e) => {
    if (!gameEditid) return;
    const formData = new FormData();
    formData.append("game_name", gameName);
    formData.append("game_type", gameType);
    formData.append("provider_id", providerCode);
    formData.append("game_image", gameImage);
    formData.append("status", status);
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    if (!token) return;
    setLoading(true);
    const res = await postMethodFormData(
      GAME_UPDATE_API(gameEditid),
      formData,
      token.token,
      "PUT"
    );
    res && setLoading(false);
    if (res?.status === "success") {
      setLoading(false);
      setEditGame(false);
      setRefr(!refr);
      setGameName("");
      setGameType("");
      setProviderCode("");
      setGameImage("");
      return;
    } else {
      SweetAlert.fire({
        icon: "error",
        width: 300,
        title: "Sorry!",
        text:
          (res?.field ?? "") +
          " " +
          (res?.message ?? "Something wrong, Try again!"),
      });
      return;
    }
  };

  return (
    <>
      <Layout>
        <Breadcrumbs title="Games" />
        <Container fluid={true}>
        {loading && <Loader />}
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader className="py-4 d-flex justify-content-end">
                  <Button
                    color=""
                    className="text-white"
                    style={{ backgroundColor: "#001753" }}
                    onClick={createOpenModal}
                  >
                    Create
                  </Button>
                </CardHeader>
                <CardBody className="position-relative">
                  <TableScroll>
                    <Table className="table-border-vertical">
                      <thead>
                        <tr className="bg-dark">
                          <th className="text-center text-white" scope="col">
                            <b>No.</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Photo</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Provider</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Game Type</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Type</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Status</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Action</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.length > 0 &&
                          data?.map((el, i) => (
                            <tr key={i} className="align-middle">
                              <th className="text-center" scope="row">{`${
                                i + 1
                              }.`}</th>
                              <td className="text-center">
                                <div
                                  style={{
                                    width: "50px",
                                    borderRadius: "5px",
                                    overflow: "hidden",
                                    margin: "auto",
                                  }}
                                >
                                  <ModalImage
                                    alt={el?.game_name}
                                    small={el?.game_image}
                                    large={el?.game_image}
                                  />
                                </div>
                              </td>
                              <th className="text-center">
                                {el.game_name}
                              </th>
                              <td className="text-center">
                                {el.provider_name}
                              </td>
                              <td className="text-center">
                                {el.game_type}
                              </td>
                              <td className="text-center">
                                {el.status === "active" ? (
                                  <i className="fa fa-circle font-success f-12" />
                                ) : (
                                  <i className="fa fa-circle font-danger f-12" />
                                )}
                              </td>
                              <td className="text-center">
                                <div>
                                  <span
                                    onClick={() => {
                                      editOpenModal(el);
                                      setGameEditid(el.id);
                                    }}
                                  >
                                    <i
                                      className={`fa fa-pencil ${
                                        el.status === "active"
                                          ? "font-success"
                                          : "font-danger"
                                      }`}
                                      style={{
                                        width: 35,
                                        fontSize: 18,
                                        padding: 11,
                                        color: "rgb(40, 167, 69)",
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  </span>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </TableScroll>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
      {
        <Modal isOpen={createGame} toggle={createOpenModal} centered>
          <Form onSubmit={createSubmitHandler}>
            <ModalHeader toggle={createOpenModal}>Create Game</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label htmlFor="">Game Name</Label>
                <Input
                  className="form-control"
                  type="text"
                  placeholder="Game Name"
                  onChange={gameNameHandler}
                />
                {gameNameValidate && (
                  <div className="invalid-feedback d-block">
                    {gameNameValidate}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">Game Type</Label>
                <Input
                  className="form-control"
                  type="text"
                  placeholder="Game Type"
                  onChange={gameTypeHandler}
                />
                {gameTypeValidate && (
                  <div className="invalid-feedback d-block">
                    {gameTypeValidate}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">Provider</Label>
                <Input
                  type="select"
                  name="select"
                  className="form-control"
                  onChange={providerHandler}
                >
                  <option value="">Choose</option>
                  {providers?.length > 0 &&
                    providers?.map((el, i) => (
                      <option key={i} value={el.id}>
                        {el.full_name}
                      </option>
                    ))}
                </Input>
                {providerCodeValidate && (
                  <div className="invalid-feedback d-block">
                    {providerCodeValidate}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">Photo</Label>
                <Input
                  className="form-control"
                  type="file"
                  placeholder=""
                  onChange={(e) => {
                    setGameImage(e.target.files[0]);
                    setGameImageValidate("");
                  }}
                />
                {gameImageValidate && (
                  <div className="invalid-feedback d-block">
                    {gameImageValidate}
                  </div>
                )}
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={createOpenModal}>
                Close
              </Button>
              <Button color="primary" type="submit">
                Save
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      }
      {
        <Modal isOpen={editGame} toggle={editOpenModal} centered>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              editSubmitHandler();
            }}
          >
            <ModalHeader toggle={editOpenModal}>Edit Game</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label htmlFor="">Game Name</Label>
                <Input
                  className="form-control"
                  defaultValue={gameName}
                  type="text"
                  placeholder="Game Name"
                  onChange={gameNameHandler}
                />
                {gameNameValidate && (
                  <div className="invalid-feedback d-block">
                    {gameNameValidate}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">Game Type</Label>
                <Input
                  className="form-control"
                  defaultValue={gameType}
                  type="text"
                  placeholder="Game Type"
                  onChange={gameTypeHandler}
                />
                {gameTypeValidate && (
                  <div className="invalid-feedback d-block">
                    {gameTypeValidate}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">Provider</Label>
                <Input
                  type="select"
                  name="select"
                  className="form-control"
                  onChange={providerHandler}
                  value={providerCode}
                >
                  <option value="">Choose</option>
                  {providers?.length > 0 &&
                    providers?.map((el, i) => (
                      <option key={el.id} value={el.id}>
                        {el.full_name}
                      </option>
                    ))}
                </Input>
                {providerCodeValidate && (
                  <div className="invalid-feedback d-block">
                    {providerCodeValidate}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">Photo</Label>
                <Input
                  className="form-control"
                  type="file"
                  placeholder=""
                  onChange={(e) => {
                    setGameImage(e.target.files[0]);
                    setGameImageValidate("");
                  }}
                />
                {gameImageValidate && (
                  <div className="invalid-feedback d-block">
                    {gameImageValidate}
                  </div>
                )}
              </FormGroup>
              <Row>
                <Col>
                  <div className="mb-0">
                    <Label>Payment Status</Label>
                    <Input
                      type="select"
                      name="select"
                      className="form-control form-control-sm digits"
                      onChange={statusHandler}
                      value={status}
                    >
                      <option value="">Choose</option>
                      <option value="active">Active</option>
                      <option value="disable">Disabled</option>
                    </Input>
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={editOpenModal}>
                Close
              </Button>
              <Button color="primary" type="submit">
                Save
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      }
    </>
  );
};

export default Games;
